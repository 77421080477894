import React from 'react';
import '../stylesheets/Cgu.css';
import logo from '../index/logo.png';

function Cgu() {
  return (
    <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>
      <div className="title-cgu">
        <h3>Conditions générales d'utilisation</h3>
        <img
          style={{ paddingTop: '1%', paddingBottom: '1%' }}
          src={logo}
          width="275"
        />
        <p style={{ color: '#404040', fontStyle: 'italic' }}>
          En vigueur au 05/10/2022
        </p>
      </div>
      <div
        style={{ marginLeft: '4%', marginRight: '4%', textAlign: 'justify' }}
      >
        <p>
          Les présentes conditions générales d'utilisation (dites « CGU ») ont
          pour objet l'encadrement juridiquedes modalités de mise à disposition
          du site et des services par _______________ et de définir
          lesconditions d’accès et d’utilisation des services par «
          l'Utilisateur ».Les présentes CGU sont accessibles sur le site à la
          rubrique «CGU».Toute inscription ou utilisation du site implique
          l'acceptation sans aucune réserve ni restriction desprésentes CGU par
          l’utilisateur. Lors de l'inscription sur le site via le Formulaire
          d’inscription, chaqueutilisateur accepte expressément les présentes
          CGU en cochant la case précédant le texte suivant : «Je reconnais
          avoir lu et compris les CGU et je les accepte ».En cas de
          non-acceptation des CGU stipulées dans le présent contrat,
          l'Utilisateur se doit de renoncerà l'accès des services proposés par
          le site.lanterne se réserve le droit de modifier unilatéralement et à
          tout moment le contenu des présentesCGU.
        </p>

        <p style={{ color: '#203552' }}>ARTICLE 1 : Les mentions légales</p>
        <p>
          L’édition et la direction de la publication du site lanterne est
          assurée par _______________, domicilié_______________.Numéro de
          téléphone est _______________Adresse e-mail
          _______________.L'hébergeur du site lanterne est la société
          _______________, dont le siège social est situé au_______________,
          avec le numéro de téléphone : _______________.
        </p>

        <p style={{ color: '#203552' }}>ARTICLE 2 : Accès au site</p>
        <p>
          Le site lanterne permet à l'Utilisateur un accès gratuit aux services
          suivants :Le site internet propose les services suivants :Propose des
          itinairaires qui réduise le sentiment d'insécuritéLe site est
          accessible gratuitement en tout lieu à tout Utilisateur ayant un accès
          à Internet. Tous lesfrais supportés par l'Utilisateur pour accéder au
          service (matériel informatique, logiciels, connexionInternet, etc.)
          sont à sa charge.L’Utilisateur non membre n'a pas accès aux services
          réservés. Pour cela, il doit s’inscrire enremplissant le formulaire.
          En acceptant de s’inscrire aux services réservés, l’Utilisateur
          membres’engage à fournir des informations sincères et exactes
          concernant son état civil et ses coordonnées,notamment son adresse
          email.
          <br />
          Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à
          l'aide de son identifiant et de son motde passe qui lui seront
          communiqués après son inscription.Tout Utilisateur membre
          régulièrement inscrit pourra également solliciter sa désinscription en
          serendant à la page dédiée sur son espace personnel. Celle-ci sera
          effective dans un délai raisonnable.Tout événement dû à un cas de
          force majeure ayant pour conséquence un dysfonctionnement du siteou
          serveur et sous réserve de toute interruption ou modification en cas
          de maintenance, n'engage pasla responsabilité de lanterne. Dans ces
          cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeurde
          toute interruption ou suspension de service, même sans
          préavis.L'Utilisateur a la possibilité de contacter le site par
          messagerie électronique à l’adresse email del’éditeur communiqué à
          l’ARTICLE 1.
        </p>

        <p style={{ color: '#203552' }}>ARTICLE 3 : Collecte des données</p>
        <p>
          Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans lamesure où il ne collecte aucune
          donnée concernant les Utilisateurs.
        </p>
        <p style={{ color: '#203552' }}>ARTICLE 4 : Propriété intellectuelle</p>
        <p>
          Les marques, logos, signes ainsi que tous les contenus du site
          (textes, images, son...) font l'objetd'une protection par le Code de
          la propriété intellectuelle et plus particulièrement par le droit
          d'auteur.L'Utilisateur doit solliciter l'autorisation préalable du
          site pour toute reproduction, publication, copie desdifférents
          contenus. Il s'engage à une utilisation des contenus du site dans un
          cadre strictement privé,toute utilisation à des fins commerciales et
          publicitaires est strictement interdite.Toute représentation totale ou
          partielle de ce site par quelque procédé que ce soit, sans
          l’autorisationexpresse de l’exploitant du site Internet constituerait
          une contrefaçon sanctionnée par l’article L 335-2et suivants du Code
          de la propriété intellectuelle.Il est rappelé conformément à l’article
          L122-5 du Code de propriété intellectuelle que l’Utilisateur
          quireproduit, copie ou publie le contenu protégé doit citer l’auteur
          et sa source.
        </p>
        <p style={{ color: '#203552' }}>ARTICLE 5 : Responsabilité</p>
        <p>
          es sources des informations diffusées sur le site lanterne sont
          réputées fiables mais le site negarantit pas qu’il soit exempt de
          défauts, d’erreurs ou d’omissions.Les informations communiquées sont
          présentées à titre indicatif et général sans valeur
          contractuelle.Malgré des mises à jour régulières, le site lanterne ne
          peut être tenu responsable de la modificationdes dispositions
          administratives et juridiques survenant après la publication. De même,
          le site ne peutêtre tenue responsable de l’utilisation et de
          l’interprétation de l’information contenue dans ce site.L'Utilisateur
          s'assure de garder son mot de passe secret. Toute divulgation du mot
          de passe, quelleque soit sa forme, est interdite. Il assume les
          risques liés à l'utilisation de son identifiant et mot depasse. Le
          site décline toute responsabilité.
          <br />
          Le site lanterne ne peut être tenu pour responsable d’éventuels virus
          qui pourraient infecterl’ordinateur ou tout matériel informatique de
          l’Internaute, suite à une utilisation, à l’accès, ou autéléchargement
          provenant de ce site.La responsabilité du site ne peut être engagée en
          cas de force majeure ou du fait imprévisible etinsurmontable d'un
          tiers.
        </p>
        <p style={{ color: '#203552' }}>ARTICLE 6 : Liens hypertextes</p>
        <p>
          Des liens hypertextes peuvent être présents sur le site. L’Utilisateur
          est informé qu’en cliquant sur cesliens, il sortira du site lanterne.
          Ce dernier n’a pas de contrôle sur les pages web sur
          lesquellesaboutissent ces liens et ne saurait, en aucun cas, être
          responsable de leur contenu.
        </p>
        <p style={{ color: '#203552' }}>ARTICLE 7 : Cookies</p>
        <p>
          L’Utilisateur est informé que lors de ses visites sur le site, un
          cookie peut s’installer automatiquementsur son logiciel de
          navigation.Les cookies sont de petits fichiers stockés temporairement
          sur le disque dur de l’ordinateur del’Utilisateur par votre navigateur
          et qui sont nécessaires à l’utilisation du site lanterne. Les cookies
          necontiennent pas d’information personnelle et ne peuvent pas être
          utilisés pour identifier quelqu’un. Uncookie contient un identifiant
          unique, généré aléatoirement et donc anonyme. Certains cookiesexpirent
          à la fin de la visite de l’Utilisateur, d’autres restent.L’information
          contenue dans les cookies est utilisée pour améliorer le site
          lanterne.En naviguant sur le site, L’Utilisateur les
          accepte.L’Utilisateur doit toutefois donner son consentement quant à
          l’utilisation de certains cookies.A défaut d’acceptation,
          l’Utilisateur est informé que certaines fonctionnalités ou pages
          risquent de luiêtre refusées.L’Utilisateur pourra désactiver ces
          cookies par l’intermédiaire des paramètres figurant au sein de
          sonlogiciel de navigation.
        </p>
        <p style={{ color: '#203552' }}>
          ARTICLE 8 : Publication par l’Utilisateur
        </p>
        <p>
          Le site permet aux membres de publier les contenus suivants
          :signalement.Dans ses publications, le membre s’engage à respecter les
          règles de la Netiquette (règles de bonneconduite de l’internet) et les
          règles de droit en vigueur.Le site peut exercer une modération sur les
          publications et se réserve le droit de refuser leur mise enligne, sans
          avoir à s’en justifier auprès du membre.Le membre reste titulaire de
          l’intégralité de ses droits de propriété intellectuelle. Mais en
          publiant unepublication sur le site, il cède à la société éditrice le
          droit non exclusif et gratuit de représenter,reproduire, adapter,
          modifier, diffuser et distribuer sa publication, directement ou par un
          tiers autorisé,dans le monde entier, sur tout support (numérique ou
          physique), pour la durée de la propriétéintellectuelle. Le Membre cède
          notamment le droit d'utiliser sa publication sur internet et sur
          lesréseaux de téléphonie mobile.
          <br />
          La société éditrice s'engage à faire figurer le nom du membre à
          proximité de chaque utilisation de sapublication.Tout contenu mis en
          ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur
          s'engage à nepas mettre en ligne de contenus pouvant porter atteinte
          aux intérêts de tierces personnes. Toutrecours en justice engagé par
          un tiers lésé contre le site sera pris en charge par l'Utilisateur.Le
          contenu de l'Utilisateur peut être à tout moment et pour n'importe
          quelle raison supprimé ou modifié par le site, sans préavis.
        </p>
        <p style={{ color: '#203552' }}>
          ARTICLE 9 : Droit applicable et juridiction compétente
        </p>
        <p>
          La législation française s'applique au présent contrat. En cas
          d'absence de résolution amiable d'unlitige né entre les parties, les
          tribunaux français seront seuls compétents pour en connaître.Pour
          toute question relative à l’application des présentes CGU, vous pouvez
          joindre l’éditeur auxcoordonnées inscrites à l’ARTICLE 1.
        </p>
      </div>
    </div>
  );
}

export default Cgu;
