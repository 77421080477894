import * as React from 'react';
import '../stylesheets/Home.css';
import '../stylesheets/Roadmap.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

function RoadmapSection() {
  return (
    <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>
      <div style={{ display: 'flex' }}>
        <div className="title-roadmap">
          <h2>Timeline</h2>
        </div>
      </div>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Moonshot
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Septembre 2022
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginRight: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-right"
            >
              La moonshot d'Epitech consiste en deux semaines d'idéalisation en
              équipe sur différents sujets d'innovation.
              <br />
              Cet exercice a permis d'imaginer les bases du projet Lanterne.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Definition du projet
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Octobre 2022 - Novembre 2022
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginLeft: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-left"
            >
              À l'aide d'un spécialiste en création d'entreprises et de projet,
              nous avons pu définir plus précisément ce qu'étaient les attentes
              et les besoins de Lanterne.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Forward
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Décembre 2022
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginRight: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-right"
            >
              Durant la forward, nous avons pu créer un Proof of Concept pour
              Lanterne.
              <br />
              L'équipe a également commencé à créer l'image marketing de
              Lanterne.
              <br />
              La charte graphique ainsi que le logo a été réalisé.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Test & Learn
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Avril 2023 - Juin 2023
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginLeft: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-left"
            >
              Ce premier sprint a permis à Lanterne de créer les maquettes du
              projet, mais a également permis d'essayer diverses méthodes
              d'organisation et moyens de communication, ainsi que de mettre en
              place des outils d'intégration continue.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Management & Processes
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Juillet 2023 - Octobre 2023
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginRight: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-right"
            >
              Durant cette période, en plus de continuer le développement de
              l'application, nous avons défini les particularités de notre
              gestion de projet, ainsi que notre gestion des désaccords et des
              conflits internes au groupe.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Fast Forward
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Novembre 2023 - Janvier 2024
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginLeft: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-left"
            >
              Pendant ce sprint, nous avons pu booster le développement de
              Lanterne.
              <br />
              Nous avons donc pu avoir une avancée significative sur les
              fonctionnalités principales de notre application.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Beta & Growth Hacking
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Février 2024 - Mai 2024
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginRight: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-right"
            >
              L'avancée précédente nous permet de réaliser la bêta de notre
              application.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Cosolidation
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Juin 2024 - Septembre 2024
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginLeft: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-left"
            >
              L'objectif sera de lancer notre bêta et de faire de l'acquisition
              utilisateur.
              <br />
              Nous pourrons donc commencer à écouter nos utilisateurs et de
              prendre en compte leurs retours.
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ backgroundColor: '#FDAA41' }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h5" component="span">
              Launch & Metrics
            </Typography>
            <Typography
              sx={{ color: '#a0a0a0', fontStyle: 'italic', fontSize: '14px' }}
            >
              Octobre 2024 - Décembre 2024
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                marginRight: '40%',
                marginTop: '1%',
                marginBottom: '1%',
              }}
              className="description-right"
            >
              Lancement de Lanterne à proprement parler, et mise en place
              d'outils nécessaires au suivi du projet.
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}

export default RoadmapSection;
