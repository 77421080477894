import React from 'react';
import "../stylesheets/Footer.css";
import {  Link } from "react-router-dom";
import logo from "../index/logo.webp"

function Footer() {
    return (
        <div className="footer-distributed">
            <div className="footer-left">
                <img className="footer-img" src={logo} alt="/" width="70" />
                <p className="footer-copyright">
                    Lanterne - © Copyright 2022. All Rights Reserved.
                </p>
            </div>
            <div className="footer-center">
                <div className="footer-links">
                    <Link to="/" className="link-1">Accueil</Link>
                </div>

                <div className="footer-links">
                    <Link to="/nousContacter" className="link-1">Nous contacter</Link>
                </div>

                <div className="footer-links">
                    <Link to="/devenirTesteur" className="link-1">Devenir Testeur</Link>
                </div>

                <div className="footer-links">
                    <Link to="/faq" className="link-1">FAQ</Link>
                </div>

                <div className="footer-links">
                    <Link to="/cgu" className="link-1">CGU</Link>
                </div>
            </div>

            <div className="footer-right">

                <p className="footer-company-about">
                    <span>Suivez nous sur :</span>
                </p>

                <div className="footer-icons">

                    <a href="https://shlink.dev-lanterne.fr/instagram" target="_blank" className="footer-social-network-icons w-inline-block"><img src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbfe70fcf5a0514c5b1da_Instagram%20Icon.svg" width="20" alt="Instagram icon"/></a>
                    <a href="https://shlink.dev-lanterne.fr/linkedin" target="_blank" className="footer-social-network-icons w-inline-block"><img src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dc0002f2b676eb4ba0869_LinkedIn%20Icon.svg" width="20" alt="LinkedIn Icon"/></a>
                    <a href="https://shlink.dev-lanterne.fr/twitter" target="_blank" className="footer-social-network-icons w-inline-block"><img src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbf0a2f2b67e3b3ba079c_Twitter%20Icon.svg" width="20" alt="Twitter icon"/></a>

                </div>

            </div>

        </div>


    );
}

export default Footer;