import * as React from 'react';
import '../stylesheets/Home.css';
import '../stylesheets/Footer.css';
import logo from '../index/logo.png';

function ConceptSection() {
  return (
    <div style={{ display: 'flex', paddingTop: '2%', paddingBottom: '2%' }}>
      <div className="column-left">
        <h3 className="subtitle">Lanterne</h3>
        <h3 className="subtitle">
          la solution qui éclairera votre chemin dans la nuit
        </h3>
        <p>
          Lanterne est une application à 100% gratuite
          <br />
          de génération d'itinéraires plus sécuritaires et
          <br />
          rassurants pour les piétons afin de réduire
          <br />
          le sentiment d'insécurité dans les rues
        </p>
        <div className="footer-icons">
          <a
            href="https://shlink.dev-lanterne.fr/instagram"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbfe70fcf5a0514c5b1da_Instagram%20Icon.svg"
              width="20"
              alt="Instagram icon"
            />
          </a>
          <a
            href="https://shlink.dev-lanterne.fr/linkedin"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dc0002f2b676eb4ba0869_LinkedIn%20Icon.svg"
              width="20"
              alt="LinkedIn Icon"
            />
          </a>
          <a
            href="https://shlink.dev-lanterne.fr/twitter"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbf0a2f2b67e3b3ba079c_Twitter%20Icon.svg"
              width="20"
              alt="Twitter icon"
            />
          </a>
        </div>
      </div>
      <div className="column-right">
        <img className="logo-concept" src={logo} width="250" />
      </div>
    </div>
  );
}

export default ConceptSection;
