import React, { useState } from 'react';
import '../stylesheets/Faq.css';
import logo from '../index/banner.jpg';
import faqsData from '../index/faq.json';

function Faq() {
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const handleQuestion = (id) => {
    var element = document.getElementById('slider' + id);
    element.classList.toggle('slide-down');

    if (expandedIndexes.includes(id)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== id));
    } else {
      setExpandedIndexes([...expandedIndexes, id]);
    }
  };

  return (
    <div>
      <img src={logo} alt="lanterne logo" style={{ width: '100%' }} />
      <div className="banner-text">
        <h className="title">FAQ</h>
      </div>
      <div className="faq-container">
        {faqsData.questions_reponses.map((faq, index) => (
          <div key={index} className="faq-item">
            <p className="faq-question" onClick={() => handleQuestion(index)}>
              {faq.Question}
              <span className="toggle-icon">
                {expandedIndexes.includes(index) ? '-' : '+'}
              </span>
            </p>
            <div id={'slider' + index} className="slide-up">
              <div className="faq-answer">{faq.Reponse}</div>
            </div>
            <div className="custom-element"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
