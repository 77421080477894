import * as React from 'react';

import '../stylesheets/Home.css';
import '../stylesheets/Equipe.css';
import Chloe from '../index/chloe.jpg';
import Guillaume from '../index/guillaume.png';
import Lucie from '../index/lucie.jpg';
import Mylo from '../index/mylo.png';
import Nathan from '../index/nathan.png';
import Paul from '../index/paul.png';
import Valentin from '../index/valentin.png';
import linkedin from '../index/linkedin.svg';

function EquipeSection() {
  return (
    <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>
      <div className="title-fonctionnalites">
        <h2>Notre équipe</h2>
      </div>
      <div className="container" style={{ paddingLeft: '15%' }}>
        <div className="column">
          <img className="photo-round" src={Chloe} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Chloé Lere</p>
            <p className="subtext">Gestion de projet</p>
            <p className="subtext">développeuse full stack</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/QpneJ"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className="column">
          <img className="photo-round" src={Nathan} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Nathan Chiodin</p>
            <p className="subtext">Résponsable Devops</p>
            <p className="subtext">développeur back-end</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/Wk10c"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
        <div className="column">
          <img className="photo-round" src={Paul} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Paul Petit</p>
            <p className="subtext">Réspondable Front</p>
            <p className="subtext">développeur front</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/uXApi"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: '8%' }} className="container">
        <div className="column">
          <img className="photo-round" src={Mylo} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Mylo Jeandat</p>
            <p className="subtext">Mobile développeur</p>
            <p className="subtext">développeur front</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/zX3xa"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
        <div className="column">
          <img className="photo-round" src={Guillaume} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Guillaume Sudol</p>
            <p className="subtext">Mobile développeur</p>
            <p className="subtext">développeur front</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/WuZrV"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
        <div className="column">
          <img className="photo-round" src={Lucie} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Lucie Thomas</p>
            <p className="subtext">Algorithme développeuse</p>
            <p className="subtext">développeuse full stack</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/ivFfK"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
        <div className="column">
          <img className="photo-round" src={Valentin} alt="Logo LinkedIn" />
          <div>
            <p className="subtext">Valentin Gozillon</p>
            <p className="subtext">Web développeur</p>
            <p className="subtext">développeur front</p>
          </div>
          <div className="linkedin">
            <a
              href="https://shlink.dev-lanterne.fr/L435T"
              target="_blank"
              className="linkedin-link"
            >
              <img src={linkedin} alt="Logo LinkedIn" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipeSection;
