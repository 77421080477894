import React, { useState } from 'react';
import '../stylesheets/Contact.css';
import emailjs from 'emailjs-com';

function DevenirTesteur() {
  const [isFill, setIsFill] = useState(true)
  const [success, setSuccess] = useState(false)
  const [underage, setUnderage] = useState(false)

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    age: '',
    from_email: '',
    commentaire: ''
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function sendEmail(formData, event) {
    event.preventDefault();
    const service_id = process.env.REACT_APP_SERVICE_ID;
    const template_id = process.env.REACT_APP_TEMPLATE_ID_TESTEUR;
    const user_id = process.env.REACT_APP_USER_ID;

    const lastname = formData.get("lastname");
    const firstname = formData.get("firstname");
    const age = formData.get("age");
    const email = formData.get("from_email");

    if (!email || !age || !firstname || !lastname) {
      setIsFill(false)
      return;
    }

    if (age < 17) {
      setUnderage(true)
      setFormData(prevState => ({
        ...prevState,
        firstname: '',
        lastname: '',
        age: '',
        from_email: '',
        commentaire: ''
      }));
      setIsFill(true)
      return;
    }

    if (!email.includes('@')) {
      setIsFill(false);
      setFormData(prevState => ({
        ...prevState,
        from_email: ''
      }));
      return;
    }


    emailjs.sendForm(service_id, template_id, event.target, user_id)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSuccess(true);
        setIsFill(true);
        setUnderage(false)
        setFormData(prevState => ({
          ...prevState,
          firstname: '',
          lastname: '',
          age: '',
          from_email: '',
          commentaire: ''
        }));
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <div>
      <h1 className='title-testeur'>Devenir testeur pour Lanterne</h1>
      <div className='explication'>
        <p>
          Lanterne est à la recherche active de testeurs pour évaluer notre application mobile. Étant nouveau sur le marché, Lanterne accorde une importance capitale à la qualité et à la fiabilité de ses produits, et votre contribution en tant que testeur jouera un rôle clé dans l'amélioration de notre application.
          <br />
          <br />
          L'objectif de ce processus de test est de garantir une expérience utilisateur exceptionnelle en identifiant les éventuels bugs, en évaluant la convivialité de l'interface et en recueillant des retours constructifs sur les fonctionnalités. En participant à ce programme, vous aurez l'opportunité de façonner directement le développement de notre application, tout en bénéficiant d'un avant-goût exclusif de ses caractéristiques uniques.
          <br />
          <br />
          Pour participer à ce programme de test, veuillez remplir le formulaire d'inscription ci-dessous. Nous vous encourageons à partager cette opportunité avec vos contacts et sur vos réseaux sociaux afin d'impliquer un maximum de personnes et d'assurer une diversité de retours.
          <br />
          <br />
          Nous sommes impatients de travailler avec vous pour créer une application mobile exceptionnelle et nous vous remercions d'avance pour votre précieuse contribution.
        </p>
      </div>

      <div style={{ display: "flex", textAlign: "center", flexDirection: "column", alignItems: "center" }}>
        {
          isFill ?
            <div></div>
            :
            <h className='error-message'>Veuillez remplir correctement tous les champs du formulaire.</h>
        }
        {
          underage ?
            <h className='error-message'>Vous êtes trop jeune pour participer à se programme</h>
            :
            <div></div>
        }
        {
          success ?
            <h className='sucess-message'>Votre candidature a été envoyé avec succès !</h>
            :
            <div></div>
        }
        <form style={{ width: "60%" }} onSubmit={(e) => sendEmail(new FormData(e.target), e)}>
          <div className='container-input'>
            <input className='input-style-dt' name="firstname" value={formData.firstname} placeholder="Prenom" onChange={handleChange} />
            <input className='input-style-dt' name="lastname" placeholder="Nom" value={formData.lastname} onChange={handleChange} />
            <input className='input-style-dt' name="age" type="number" placeholder="Age" min="10" max="100" value={formData.age} onChange={handleChange} />
          </div>
          <input className='input-email' name="from_email" placeholder="E-mail" value={formData.from_email} onChange={handleChange} />
          <textarea className='textarea-style' name="commentaire" placeholder="Commentaire" value={formData.commentaire} onChange={handleChange} />
          <div>
            <button className="submit-style" type="submit">Envoyer</button>
          </div>
        </form>
      </div>
      <div className='download-container'>
        <a href="https://shlink.dev-lanterne.fr/application" download="lanterne-app.apk"><button class="button-download" role="button">Télécharger Lanterne</button></a>
      </div>
    </div>
  );
}

export default DevenirTesteur;
