import React from 'react';
import '../stylesheets/Home.css';
import ConceptSection from '../components/ConceptSection';
import FonctionalitesSection from '../components/FonctionalitesSection';
import EquipeSection from '../components/EquipeSection';
import RoadmapSection from '../components/RoadmapSection';

function Home() {
  function redirection(e, idToRedirect) {
    const element = document.getElementById(idToRedirect);
    element.scrollIntoView(true);
  }

  return (
    <div>
      <div className="section-redirection">
        <a
          className="button-section"
          onClick={(e) => redirection(e, 'concept')}
        >
          Concept
        </a>
        <a
          className="button-section"
          onClick={(e) => redirection(e, 'fonctionalites')}
        >
          Fonctionnalités
        </a>
        <a className="button-section" onClick={(e) => redirection(e, 'equipe')}>
          Notre équipe
        </a>
        <a
          className="button-section"
          onClick={(e) => redirection(e, 'roadmap')}
        >
          Timeline
        </a>
      </div>

      <div className="section">
        <div id="concept" className="concept">
          <ConceptSection />
        </div>
        <div id="fonctionalites" className="fonctionalites">
          <FonctionalitesSection />
        </div>
        <div id="equipe" className="equipe">
          <EquipeSection />
        </div>
        <div id="roadmap" className="roadmap">
          <RoadmapSection />
        </div>
      </div>
    </div>
  );
}

export default Home;
