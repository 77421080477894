import * as React from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/TopBar.css';
import logo from '../index/logo.webp';
import '../stylesheets/Download.css'

function TopBar() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1%',
        backgroundColor: '#22272E',
        alignItems: "center",
      }}
    >
      <Link to="/">
        <div style={{ marginLeft: '2vw' }}>
          <img src={logo} alt="lantenre logo" style={{ width: '25px' }} />
        </div>
      </Link>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: "center",
        }}
      >
        <li className="underLine2 hide_on_responsive">
          <Link style={{marginTop: "10%"}} to="/">ACCUEIL</Link>
        </li>
        <li className="underLine2 hide_on_responsive">
          <Link to="/nousContacter">NOUS CONTACTER</Link>
        </li>
        <li className="underLine2 hide_on_responsive">
          <Link to="/devenirTesteur">DEVENIR TESTEUR</Link>
        </li>
        <li className="underLine2 hide_on_responsive">
          <Link to="/faq">FAQ</Link>
        </li>
        <a href="https://shlink.dev-lanterne.fr/application" download="lanterne-app.apk" class="btn-slide">
          <span class="circle"><i class="fa fa-download"></i></span>
          <span class="title2">Télécharger</span>
          <span class="title-hover2"> Clique ici</span>
        </a>
      </div>
    </div>
  );
}
export default TopBar;

