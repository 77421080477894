import React, { useState } from 'react';
import '../stylesheets/Contact.css';
import '../stylesheets/Faq.css';
import '../stylesheets/Error.css';
import banner from '../index/banner_contact.jpg';
import emailjs from 'emailjs-com';



function NousContacter() {
  const [isFill, setIsFill] = useState(true)
  const [success, setSuccess] = useState(false)
  const [formData, setFormData] = useState({
    from_email: '',
    category: '',
    message: ''
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }


  function sendEmail(formData, event) {
    event.preventDefault();
    const service_id = process.env.REACT_APP_SERVICE_ID;
    const template_id = process.env.REACT_APP_TEMPLATE_ID;
    const user_id = process.env.REACT_APP_USER_ID;

    const email = formData.get("from_email");
    const category = formData.get("category");
    const message = formData.get("message");

    if (!email || !category || !message) {
      setIsFill(false)
      return;
    }

    if (!email.includes('@')) {
      setIsFill(false);
      setFormData({
        from_email: ''
      });

      return;
    }

    emailjs.sendForm(service_id, template_id, event.target, user_id)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSuccess(true);
        setIsFill(true);
        setFormData({
          from_email: '',
          category: '',
          message: ''
        });

      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

  return (
    <div>
      <img src={banner} alt="lanterne logo" style={{ width: '100%' }} />
      <div className="banner-text">
        <h className="title">Nous Contacter</h>
      </div>
      <div style={{ display: "flex", textAlign: "center", flexDirection: "column", alignItems: "center" }}>
        <p style={{ alignContent: "center" }}>Merci de remplir le formulaire.<br />Notre équipe s'engage à traiter votre message au plus vite.</p>
      </div>
      <div style={{ display: "flex", textAlign: "center", flexDirection: "column", alignItems: "center" }}>
        {
          isFill ?
            <div></div>
            :
            <h className='error-message'>Veuillez remplir correctement tous les champs du formulaire.</h>
        }
        {
          success ?
            <h className='sucess-message'>Votre message a été envoyé avec succès !</h>
            :
            <div></div>
        }

        <form style={{width: "60%"}} onSubmit={(e) => sendEmail(new FormData(e.target), e)}>
          <input className='input-style' name="from_email" placeholder="E-mail" value={formData.from_email} onChange={handleChange}/>
          <select className='select-style' name="category" value={formData.category} onChange={handleChange}>
            <option value="">Séléctionner la raison de votre message</option>
            <option value="Demande d'Information">Demande d'Information</option>
            <option value="Signalement de bug">Signalement de bug</option>
            <option value="Assistance Compte">Assistance Compte</option>
            <option value="Support technique">Support technique</option>
            <option value="Demande de partenariat">Demande de partenariat</option>
            <option value="Question Générale">Question Générale</option>
          </select>
          <textarea className='textarea-style' name="message" placeholder="Taper un message ..." value={formData.message} onChange={handleChange}/>
          <div>
            <button className="submit-style" type="submit">Envoyer</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NousContacter;
