import * as React from 'react';
import {  Routes, Route, HashRouter} from 'react-router-dom';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Cgu from './pages/Cgu';
import NousContacter from './pages/NousContacter';
import DevenirTesteur from './pages/DevenirTesteur';
import TopBar from './components/TopBar';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <HashRouter>
        <TopBar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/cgu" element={<Cgu />} />
          <Route path="/nousContacter" element={<NousContacter />} />
          <Route path="/devenirTesteur" element={<DevenirTesteur />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
